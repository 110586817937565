import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './about.css'
import { StaticImage } from "gatsby-plugin-image"
import PersonCard from "./personCard.js"

const Zespol = () => {

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1480 },
      items: 4
    },
    laptop: {
      breakpoint: { max: 1480, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const path = "../../../../static/team/"

  return (
    <Carousel responsive={responsive} infinite={true} className="m-md-4 my-xs-4">
        <PersonCard imie={"lek. wet. Tadeusz Czekalski"}
                    image={<StaticImage className="personal_image" variant="top" src={path + "tadeusz.jpg"} alt="lek. wet. Tadeusz Czekalski" height="400"/>}
                    dane={"Specjalista chirurgii. Wcześniej pracował w warszawskich klinikach całodobowych, gdzie pod okiem najlepszych specjalistów uczył się zawodu."} />
        <PersonCard imie={"lek. wet. Kaja Hawryńska"}
                    image={<StaticImage className="personal_image" variant="top" src={path + "kaja.jpg"} alt="lek. wet. Kaja Hawryńska" height="400"/>}
                    dane={"Specjalista diagnostyki laboratoryjnej. Absolwentka Wydziału Medycyny Weterynaryjnej warszawskiej SGGW z 2008 roku, w 2013 roku uzyskała tytuł specjalisty w dziedzinie weterynaryjnej diagnostyki laboratoryjnej."} />
        <PersonCard imie={"lek. wet. Mirosława Ziemba"}
                    image={<StaticImage className="personal_image" variant="top" src={path + "miroslawa.jpg"} alt="lek. wet. Mirosława Ziemba" height="400" />}
                    dane={"Kardiolog. Ukończyła Wydział Medycyny Weterynaryjnej Szkoły Głównej Gospodarstwa Wiejskiego w Warszawie pod okiem najlepszych kardiologów weterynaryjnych - dr n. wet. Magdaleny Garncarz oraz dr. Rafała Niziołka w specjalistycznej lecznicy weterynaryjnej Vetcardia."} />
        <PersonCard imie={"lek. wet. Michał Sawicki"}
                    image={<StaticImage className="personal_image" variant="top" src={path + "michal.jpg"} alt="lek. wet. Michał Sawicki" height="400"/>}
                    dane={"Internista. Absolwent Wydziału Medycyny Weterynaryjnej w Lublinie"} />
        <PersonCard imie={"lek. wet. Katarzyna Serafin"}
                    image={<StaticImage className="personal_image" variant="top" src={path + "kasia.jpg"} alt="lek. wet. Katarzyna Serafin" height="400"/>}
                    dane={"Internista. Ukończyła SGGW w Warszawie w 2018 roku."} />
        <PersonCard imie={"lek. wet. Emilia Cicherska"}
                    image={<StaticImage className="personal_image" variant="top" src={path + "emilia.jpg"} alt="lek. wet. Emilia Cicherska" height="400"/>}
                    dane={"Internista. Absolwentka wydziału Medycyny Weterynaryjnej Uniwersytetu Przyrodniczego we Wrocławiu."} />
        <PersonCard imie={"Aksel Śliwiński"}
                    image={<StaticImage className="personal_image" variant="top" src={path + "aksel.jpg"} alt="Aksel Śliwiński" height="400"/>}
                    dane={"Technik weterynarii"} />
        <PersonCard imie={"Monika"}
                    image={<StaticImage className="personal_image" variant="top" src={path + "linux.png"} alt="Monika" height="400"/>}
                    dane={"Technik weterynarii"} />
    </Carousel>
  );
}

export default Zespol;
