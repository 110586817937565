import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import './about.css'
import { StaticImage } from "gatsby-plugin-image"

const About = () => (
  <Row style={{ width: '100%' }}>
    <Col lg={{ span: 4, offset: 1}} className="col_center">
      <Container className="container_about">
        <StaticImage src="../../../../static/images/dogVet.png" alt="Pies weterynarz" width={500} height={500}/>
      </Container>
    </Col>
    <Col lg={{ span: 5, offset: 1}} className="align_div_center">
      <StaticImage src="../../../../static/logo-amicus.png" alt="Amicus" className="w-logo"/>
      <p>Centrum weterynaryjne Amicus zajmuje się leczeniem zwierząt domowych</p>
      <p>Centrum wyposażone jest w nowoczesny sprzęt. Posiadamy również własne laboratorium</p>
      <p>W naszej placówce pracuje wykwalifikowany personel o szerokiej specjalizacji, aby zaoferować Państwa zwierzętom kompleksową i najbardziej fachową pomoc</p>
    </Col>
  </Row>
);

export default About;
