import React from 'react'
import { Carousel } from 'react-bootstrap'
import { StaticImage } from "gatsby-plugin-image"
import './slideWindow.css'

const path = "../../../../static/"

const SlideWindow = () => (
  <Carousel>
    <Carousel.Item>
      <StaticImage src={path + "blog/lecznica.jpg"} alt="Gabinet weterynaryjny" className="image_container" />
      <Carousel.Caption className="background_slide">
        <h3 className="text_style">Centrum weterynaryjne Amicus</h3>
        <p>W naszej placówce pracuje wykwalifikowany personel o szerokiej specjalizacji, aby zaoferować Państwa zwierzętom kompleksową i najbardziej fachową pomoc</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <StaticImage src={path + "blog/chirurgia.jpg"} alt="Weterynaria chirurgia" className="image_container" />
      <Carousel.Caption className="background_slide">
        <h3 className="text_style">Chirurgia</h3>
        <p>Wykwalifikowani specjaliści w naszej przychodni wykonują nawet najbardziej skomplikowane zabiegi u
        Waszych przyjaciół pod kontrolą anestezjologiczną.</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <StaticImage src={path + "blog/produkty.jpg"} alt="Weterynaria leki" className="image_container" />
      <Carousel.Caption className="background_slide">
        <h3 className="text_style">Produkty weterynaryjne</h3>
        <p>W naszych placówkach zakupią Państwo szerokiej gamy produkty weterynaryjne wspomagające leczenie ale również dbające o poprawną kondycją Państwa pupila</p>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
);

export default SlideWindow;
