import React from 'react'
import { Card, Container } from 'react-bootstrap'
import './about.css'

const PersonCard = (props) => {

  return (
    <Card className="m-1">
      <Container className="container_about">
        {props.image}
        <div className="middle">
          <p className="text">{props.dane}</p>
        </div>
      </Container>
      <Card.Footer>
        <p className="text-center mb-0">{props.imie}</p>
      </Card.Footer>
    </Card>
  )
}
export default PersonCard;
