import React from 'react'
import { Row, Col } from 'react-bootstrap'
import './about.css'
import Map from './loadableMap.js'

const Kontakt = () => (
  <Row className="mt-3 ml-0" style={{ width: 'inherit' }}>
    <Col lg={{ span: 2, offset: 1}} className="col_center">
      <h3 className="text-green">"Amicus" Centrum Weterynaryjne</h3>
      <p>ul. Tatrzańska 63 paw. 40</p>
      <p>93-219, Łódź</p>
      <p>tel. kom. 730 500 250</p>
    </Col>
    <Col lg={{ span: 3}} className="col_center">
      <h4>Godziny otwarcia</h4>
      <p>Poniedziałek: 8-20<br/>
         Wtorek: 8-20<br/>
         Środa: 8-20<br/>
         Czwartek: 8-20<br/>
         Piątek: 8-20<br/>
         Sobota: 10-14<br/>
         Niedziela: nieczynne<br/>
      </p>
    </Col>
    <Col lg={{ span: 2, offset: 1}} className="col_center">
      <h3 className="text-green">"Amicus" Gabinet Weterynaryjny</h3>
      <p>ul. Maćka z Bogdańca 1 lok. A</p>
      <p>93-434, Łódź</p>
      <p>tel. kom. 727 522 422</p>
    </Col>
    <Col lg={{ span: 3}} className="col_center">
      <h4>Godziny otwarcia</h4>
      <p>Poniedziałek: 13-19<br/>
         Wtorek: 13-19<br/>
         Środa: 13-19<br/>
         Czwartek: 13-19<br/>
         Piątek: 13-19<br/>
         Sobota: 10-13<br/>
         Niedziela: nieczynne<br/>
      </p>
    </Col>
    <Col xs={12} className="map_container">
      <Map />
    </Col>
  </Row>
);

export default Kontakt;
