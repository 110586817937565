import React from 'react'
import Layout from '../components/containers/layout'
import BgImage from '../components/containers/image/loadableBgImage.js'
import About from '../components/containers/index/about.js'
import Uslugi from '../components/containers/index/uslugi.js'
import ShortAbout from '../components/containers/index/shortAbout.js'
import Zespol from '../components/containers/index/zespol.js'
import Kontakt from '../components/containers/index/kontakt.js'
import SlideWindow from '../components/containers/image/slideWindow.js'
import Seo from "../components/seo/seo"
import Footer from '../components/nav/footer'
import { getSrc } from "gatsby-plugin-image"

const seoImage = getSrc("../../static/blog/przychodnia.jpg")

const Index = () => (
  <Layout>
    <Seo title="Amicus - centrum weterynaryjne"
         description="Usługi weterynaryjne w Łodzi"
         image={ seoImage }
    />
    <BgImage />
    <About />
    <Uslugi />
    <SlideWindow />
    <ShortAbout />
    <Zespol />
    <Kontakt />
    <Footer />
  </Layout>
)
export default Index;
