import React from 'react'
import { Container } from 'react-bootstrap'
import './about.css'

const ShortAbout = () => (
  <Container className="text_container">
    <p>Firma Amicus działa na łódzkim rynku usług weterynaryjnych od 2005 roku,
    kiedy to otworzyliśmy naszą pierwszą filię przy ul. Tartrzańskiej. Od tego
    czasu, aby zwiększyć poziom satysfakcji naszych klientów otworzyliśmy
    naszą drugą filię przy ulicy Maćka z Bogdańca, ale również założyliśmy
    własne laboratorium w celu zmniejszenia czasu oczekiwania na wyniki badań.
    W Amicus stawiamy przede wszystkim na zwierzęta, dlatego nasz personel
    jest wykwalifikowany oraz zobligowany do ciągłego rozwoju.</p>
    <div className="divider_with_triangle"></div>
  </Container>
);

export default ShortAbout;
