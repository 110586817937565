import React from 'react'
import { Card, CardDeck, Container, Row, Col } from 'react-bootstrap'
import './about.css'
import { StaticImage } from "gatsby-plugin-image"

const path = "../../../../static/oferta/"

const Uslugi = () => (
  <Container className="cards_container">
    <CardDeck>
      <Row className="mt-3 ml-0" style={{ width: 'inherit' }}>
        <Col lg={{ span: 4 }} md={{ span: 6 }} className="mb-3">
          <Card className="card_container">
            <StaticImage variant="top" src={path + "profilaktyka.jpg"} alt="Profilaktyka" width={400} height={400}/>
            <Card.Body>
              <Card.Title>Profilaktyka</Card.Title>
              <Card.Text>
                Wykonujemy szczepienia przeciwko chorobom zakaźnym zgodnie z harmonogramem szczepień psów,
                kotów, królików i fretek. Zapewniamy profilaktykę przeciw pasożytom zewnętrznym tj. pchły, kleszcze,
                świerzb oraz wewnętrznych (tasiemce, nicienie, pierwotniaki). Zalecamy okresowe badania kontrolne,
                przeprowadzamy badania przed wyjazdem oraz wystawą.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={{ span: 4 }} md={{ span: 6 }} className="mb-3">
          <Card className="card_container">
            <StaticImage variant="top" src={path + "internist.jpg"} alt="Badania internistyczne" width={400} height={400}/>
            <Card.Body>
              <Card.Title>Wizyty internistyczne, leczenie</Card.Title>
              <Card.Text>
                Jest to podstawowa opieka lekarska naszego pacjenta. W trakcie takiej wizyty uwaga kierowana jest na
                wywiad oraz badanie ogólne pacjenta Jeśli sytuacja tego wymaga zlecane są badania dodatkowe
                <br/> (np. badanie krwi, moczu, kału, badanie ultrasonograficzne, RTG). Rozpoznajemy i leczymy choroby
                wewnętrzne i zakaźne zwierząt, prowadzimy leczenie pacjentów z chorobami przewlekłymi,
                kompleksowo opiekujemy się pacjentami geriatrycznymi. Dokładamy wszelkich starań by wizyty w
                gabinecie były przeprowadzane w przyjaznej atmosferze z jak najmniejszym stresem dla Państwa pupili.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={{ span: 4 }} md={{ span: 6 }} className="mb-3">
          <Card className="card_container">
            <StaticImage variant="top" src={path + "laboratory.jpg"} alt="Laboratorium" width={400} height={400}/>
            <Card.Body>
              <Card.Title>Diagnostyka laboratoryjna</Card.Title>
              <Card.Text>
                Wykonujemy wszystkie możliwe badania (morfologia, biochemia, badania genetyczne i molekularne -
                PCR, diagnostyka chorób zakaźnych, badania moczu i kału, badania mikroskopowe, cytologiczne,
                histopatologiczne, mikrobiologiczne) w celu postawienia jak najdokładniejszej diagnozy, posiadamy
                własne laboratorium gdzie pracują osoby wyspecjalizowane w tym kierunku, a sprzęt przechodzi
                codzienne testy i kalibracje tak aby otrzymany wynik był wiarygodny.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={{ span: 4 }} md={{ span: 6 }} className="mb-3">
          <Card className="card_container">
            <StaticImage variant="top" src={path + "poloznictwo.jpg"} alt="Poloznictwo" width={400} height={400}/>
            <Card.Body>
              <Card.Title>Diagnostyka obrazowa</Card.Title>
              <Card.Text>
                Wykonujemy badanie ultrasonograficzne jamy brzusznej i klatki piersiowej oraz badanie echa serca
                (wizyty kardiologiczne). Posiadamy aparat do rentgenografii bezpośredniej dzięki któremu jesteśmy w
                stanie stawiać dokładniejsze rozpoznania w jeszcze krótszym czasie.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={{ span: 4 }} md={{ span: 6 }} className="mb-3">
          <Card className="card_container">
            <StaticImage variant="top" src={path + "chirurgia.jpg"} alt="Chirurgia" width={400} height={400}/>
            <Card.Body>
              <Card.Title>Chirurgia</Card.Title>
              <Card.Text>
                Wykwalifikowani specjaliści w naszej przychodni wykonują nawet najbardziej skomplikowane zabiegi u
                Waszych przyjaciół pod kontrolą anestezjologiczną. Specjalizujemy się w chirurgii miękkiej tj. na terenie
                klatki piersiowej i jamy brzusznej oraz twardej: zabiegi ortopedyczne w obrębie kości kończyn oraz
                kręgosłupa.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={{ span: 4 }} md={{ span: 6 }} className="mb-3">
          <Card className="card_container">
            <StaticImage variant="top" src={path + "ortopedia.jpg"} alt="Ortopedia" width={400} height={400}/>
            <Card.Body>
              <Card.Title>Ortopedia</Card.Title>
              <Card.Text>
                Badanie ortopedyczne wykonuje lek. wet. specjalista chirurgii weterynaryjnej Tadeusz Czekalski.
                Zajmujemy się kompleksowo problemami ortopedycznymi niezależnie od wielkości i wieku naszych
                pacjentów. Diagnozujemy i leczymy wady wrodzone, nabyte oraz uszkodzenia narządów ruchu.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={{ span: 4 }} md={{ span: 6 }} className="mb-3">
          <Card className="card_container">
            <StaticImage variant="top" src={path + "onkologia.jpg"} alt="Onkologia" width={400} height={400}/>
            <Card.Body>
              <Card.Title>Onkologia</Card.Title>
              <Card.Text>
                Badanie onkologiczne wykonuje lek. wet. Tadeusz Czekalski
                Zajmujemy się diagnozowaniem, leczeniem i opieką paliatywną pacjentów onkologicznych. W naszej
                lecznicy jest możliwość leczenia za pomocą chemioterapii oraz wykonujemy skomplikowane zabiegi
                onkologiczne.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={{ span: 4 }} md={{ span: 6 }} className="mb-3">
          <Card className="card_container">
            <StaticImage variant="top" src={path + "exotic.jpg"} alt="Zwierzęta egzotyczne" width={400} height={400}/>
            <Card.Body>
              <Card.Title>Zwierzęta egzotyczne</Card.Title>
              <Card.Text>
                Badanie zwierząt egzotycznych wykonuje lek. wet. Kaja Hawryńska, która od wielu lat specjalizuje się w
                chorobach u zwierząt egzotycznych, futerkowych, gadów, ptaków oraz płazów.
                Wykonujemy również zabiegi chirurgiczne przy użyciu narkozy wziewnej zapewniającej
                najbezpieczniejszy przebieg znieczulenia.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={{ span: 4 }} md={{ span: 6 }} className="mb-3">
          <Card className="card_container">
            <StaticImage variant="top" src={path + "kardiologia.jpg"} alt="Kardiologia" width={400} height={400}/>
            <Card.Body>
              <Card.Title>Kardiologia</Card.Title>
              <Card.Text>
                Badanie kardiologiczne wykonuje lek. wet. Mirka Ziemba,
                podczas wizyty kardiologicznej przeprowadzane jest pełne badanie echokardiograficzne oraz pomiar
                ciśnienia krwi i badanie elektrokardiograficzne (EKG).<br /> W razie wykrytych chorób układu krążenia lub
                wad serca zostaje wprowadzone leczenie oraz wydane zalecenia.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={{ span: 4 }} md={{ span: 6 }} className="mb-3">
          <Card className="card_container">
            <StaticImage variant="top" src={path + "okulistyka.jpg"} alt="Okulistyka" width={400} height={400}/>
            <Card.Body>
              <Card.Title>Okulistyka</Card.Title>
              <Card.Text>
                Badanie okulistyczne wykonuje lek. wet. Katarzyna Serafin
                Na wizycie okulistycznej jest wykonywana diagnostyka chorób w obrębie gałki ocznej oraz powiek.
                Posiadamy specjalistyczny sprzęt dzięki któremu jesteśmy w stanie zbadać ciśnienie wewnątrzgałkowe.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={{ span: 4 }} md={{ span: 6 }} className="mb-3">
          <Card className="card_container">
            <StaticImage variant="top" src={path + "paszport.jpg"} alt="Paszport" width={400} height={400}/>
            <Card.Body>
              <Card.Title>Paszporty dla zwierząt</Card.Title>
              <Card.Text>
                Wydawany na wizycie u lek. wet. Tadeusza Czekalskiego, który posiada uprawnienia do wystawiania i
                przygotowania pacjenta do podróży za granicę. Wykonujemy wszelkie badania i zabiegi, by umożliwić
                Państwa podopiecznemu spokojne i bezpieczne przekroczenie granicy.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </CardDeck>
  </Container>
);

export default Uslugi;
